export const colors = {
  darkBackgroundColor: '#292e33',
  contentBackgroundColor: '#f8fafd',
  white: '#ffffff',
  lightGray: '#aab6be',
  gray100: '#fbfbfb',
  gray200: '#f8fafd',
  gray250: '#f2f2f2',
  gray300: '#e7e7e7',
  gray320: '#e8e8e8',
  gray370: '#d3d3d3',
  gray400: '#d7d7d7',
  gray500: '#c0c0c0',
  gray600: '#b4b4b4',
  gray700: '#899196',
  gray750: '#637079',
  gray800: '#595959',
  gray850: '#3f4149',
  gray900: '#292e33',
  gray1000: '#191d21',
  black: 'black',
  green100: '#e6f9e5',
  green150: '#c6fcdc',
  green200: '#53fa96',
  green250: '#92e02b',
  green300: '#44d641',
  green400: '#3ec675',
  green500: '#289f25',
  green600: '#007931',
  orange100: '#fff0d8',
  orange200: '#ffe7be',
  orange250: '#ffdfc1',
  orange300: '#ffdfaa',
  orange400: '#ffbc4d',
  orange500: '#e5b15a',
  orange600: '#f49904',
  orange650: '#bd7000',
  orange700: '#ad6c00',
  orange800: '#885200',
  yellow500: '#f3db49',
  red100: '#fff3f4',
  red150: '#fad9d9',
  red200: '#f7c3c5',
  red300: '#f7445d',
  red500: '#dc324a',
  red800: '#a40000',
  blue200: '#f1f8ff',
  blue250: '#deefff',
  blue300: '#d3e9ff',
  blue400: '#95c3f2',
  blue500: '#98c4f1',
  blue600: '#5f9bd7',
  blue700: '#3ab5e3',
  blue750: '#3391ef',
  blue800: '#2d72b8',
  blueInfo: '#0092c5',
  textTagBlueBg: '#deefff',
  textTagBlueText: '#2d72b8',
  textTagGreenBg: '#c6fcdc',
  textTagGreenText: '#007931',
  textTagOrangeBg: '#ffdfc1',
  textTagOrangeText: '#885200',
  textTagGrayBg: '#e7e7e7',
  textTagGrayText: '#595959',
  textTagPurpleBg: '#e9e3ff',
  textTagPurpleText: '#664dd0',
  textTagRedBg: '#fad9d9',
  textTagRedText: '#a40000',
  purple100: '#f5f2ff',
  purple200: '#e9e3ff',
  purple400: '#7b63e2',
  purple600: '#664dd0',
  syncariBlue: '#2c8ff2',
  borderColor: '#e7e7e7',
  titleColor: '#454a4d',
  functionCyan: '#4fc5c2',
  actionPurple: '#7b63e2',
  ghostBannerBg: '#4fc5c2',
  sideNavSelectedBg: '#191d21',
  sideNavDivider: '#585f67',
  colorBoxBorder: '#e7e7e7',
  colorSelected: '#2c8ff2',
  colorBackgroundSelected: '#f1f8ff',
  propertyPanelTitleBorder: '#d7d7d7',
  colorDraftStatus: '#f5a623',
  colorApprovedStatus: '#3ec675',
  colorLabel: '#595959',
  borderColorError: '#eab3b9',
  borderError: '1px solid #eab3b9',
  colorError: '#7b000f',
  backgroundColorTableHeader: '#e7e7e7',
  colorEmptyPanelDescription: '#899196',
  colorDashboardWidgetSeparator: '#deeeff',
  colorFunctionIcons: '#4fc5c2',
  validationError: '#f5222d',
  sWhite: '#f6f6f6',
  sLightGray: '#e4e4e4',
  sMedGray: '#696969',
  sDarkGray: '#454545',
  sBlue: '#23365b',
} as const;

export const variables = {
  maxZIndex: '2147483648',
  fontSizes: {
    xxs: '0.5rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    lgr: '1.25rem',
    xl: '1.5rem',
    xxl: '1.875rem',
    '3xl': '2.25rem',
    '4xl': '3rem',
    '5xl': '4rem',
  },
  fontWeights: { thin: '100', light: '300', regular: '400', medium: '500', semibold: '600', bold: '700' },
  fontWeights500: '500',
  lineHeights: { single: '1', tight: '1.125', snug: '1.25', regular: '1.5', regloose: '1.75', loose: '2' },
  spacings: {
    z: '0',
    xxxs: '0.15rem',
    xxs: '0.25rem',
    xxsm: '0.375rem',
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    mmlg: '1.15rem',
    mlg: '1.25rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
    '3xl': '4rem',
    '4xl': '5rem',
    '5xl': '8rem',
    '6xl': '12rem',
    '7xl': '18rem',
  },
  borderRadius: { z: '0', sm: '2px', md: '4px', lg: '6px', full: '50rem', circle: '50%' },
  boxShadows: {
    sm: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',
    md: '0 1px 6px 2px rgba(0, 0, 0, 0.15)',
    lg: '0 0px 10px 2px rgba(0, 0, 0, 0.15)',
  },
  easings: { easeInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)' },
  syncariSidebarCollapseWidth: '70px',
  syncariSidebarFullWidth: '200px',
  bannerHeight: '36px',
  mainNavHeight: '52px',
  toolbarHeight: '52px',
  panelHeaderHeight: '52px',
  panelBodyHeight: 'calc(100% - 52px)',
  panelFooterHeight: '52px',
  panelWidth: '350px',
  panelWidthLarge: '550px',
  gray300: '#e7e7e7',
  sidebarRightBorder: '1px solid #e7e7e7',
} as const;
